import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 0;
`

const PageContainer = ({children, ...props}) => (
    <Container {...props}>
        {children}
    </Container>
)

export default PageContainer;