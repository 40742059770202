import React from 'react'
import Heading from '../../components/typograph/Heading'
import Layout from '../../components/layout/Layout'
import PageContainer from '../../components/layout/PageContainer'
import Hero from '../../components/hero/Hero'
import IlustratedContent from '../../components/ilustratedParagraph/IlustratedContent'
import styled from 'styled-components'
import {
  LIGHT_GRAY,
  GRAY,
  BLUE,
  DARK_GREEN,
  WHITE,
  ACQUA,
  TEXT,
  ORANGE,
} from '../../constants/Colors'

import coursesImage from '../../images/Banner-Cursos-Desk.jpg'
import coursesImageMobile from '../../images/Banner-Cursos-Mobile.jpg'
import cursoHomeopatiaVet from '../../images/curso-homeopatia-SCS.png'
import LinkButton from '../../components/buttons/LinkButton'
import GatsbyLink from 'gatsby-link'
import { CommonMeta } from '.'

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  margin-bottom: 64px;
  margin-top: 64px;

  & .content {
    margin-left: 64px;
  }

  @media (max-width: 799px) {
    flex-wrap: wrap;
    justify-content: center;

    & .noMobo {
      display: none;
    }

    & .content {
      margin-left: 0;
    }
  }
`

const HomeopatiaParaVeterinarios = () => (
  <Layout
    title="Homeopatia Veterinária e Tecnologia das Altas Diluições - HDScience"
    meta={CommonMeta(
      'cursos-e-minicursos/homeopatia-para-veterinarios',
      'Homeopatia Veterinária e Tecnologia das Altas Diluições - HDScience'
    )}
  >
    <Hero image={coursesImage} mobileImage={coursesImageMobile}>
      <Heading
        type="h1"
        style={{ color: '#fff', textAlign: 'center', paddingTop: 30 }}
      >
        CURSOS &<br />
        MINI-CURSOS
      </Heading>
    </Hero>
    <PageContainer style={{ marginBottom: 40 }}>
      <TextContainer>
        <div style={{ padding: 16 }}>
          <IlustratedContent
            image={cursoHomeopatiaVet}
            secondary={true}
            style={{
              borderRadius: '20px',
              border: `1px solid ${GRAY}`,
              maxWidth: '240px',
              padding: 20,
              backgroundColor: BLUE,
              color: DARK_GREEN,
            }}
          >
            <Heading type="h5" style={{ margin: 0 }} color={DARK_GREEN}>
              Homeopatia Veterinária e Tecnologia das Altas Diluições
            </Heading>
            2 anos | Híbrido
            <br />
            <br /> <b>Público: </b> Veterinários.
            <br /> <b>Unidade: </b> São Caetano <br />
            <div
              style={{
                marginTop: 10,
                backgroundColor: WHITE,
                width: '278px',
                marginLeft: '-20px',
                border: `1px solid ${LIGHT_GRAY}`,
                padding: '26px 0',
              }}
            >
              <LinkButton
              style={{
                marginTop: 0,
                width: 290,
                marginLeft: -10,
                display: 'block',
                padding: '5px 5px',
                lineHeight: '32px',
              }}
              to="https://api.whatsapp.com/send?phone=5511991760079"
              target="_blank"
              >
                QUERO FAZER O CURSO
              </LinkButton>
            </div>
          </IlustratedContent>
        </div>
        <div style={{ color: TEXT }} className="content">
          <Heading
            type="h3"
            style={{ margin: 0 }}
            color={DARK_GREEN}
            className="noMobo"
          >
           Homeopatia Veterinária e Tecnologia das Altas Diluições
          </Heading>
          <Heading type="h4" style={{ margin: '32px 0 16px 0' }} color={ACQUA}>
            Professores
          </Heading>
          <GatsbyLink
            to="/profissionais/"
            style={{
              textDecoration: 'none',
              color: ORANGE,
              fontWeight: 'bold',
            }}
          >
            Profª Dra. Cidéli Coelho
          </GatsbyLink>{' '}
          e{' '}
          <GatsbyLink
            to="/profissionais/"
            style={{
              textDecoration: 'none',
              color: ORANGE,
              fontWeight: 'bold',
            }}
          >
            Dr. Adalberto von Ancken
          </GatsbyLink>
          <br />
          <Heading type="h4" style={{ margin: '32px 0 16px 0' }} color={ACQUA}>
            Início
          </Heading>
          Turmas formadas em fevereiro e agosto
          <br />
          <Heading type="h4" style={{ margin: '32px 0 16px 0' }} color={ACQUA}>
            Certificação
          </Heading>
            Ebramec / HD Science
          <br />
          <Heading type="h4" style={{ margin: '32px 0 16px 0' }} color={ACQUA}>
            Carga Horária & Duração Total
          </Heading>
          24 meses ou 800 horas divididos em 4 módulos (semestrais) nos quais o
          aluno terá:
          <br />
          <ol style={{ lineHeight: 2 }}>
            <li>Teoria;</li>
            <li>Aulas Práticas;</li>
            <li>
              Elaboração de Trabalho de Conclusão de Curso (artigo de cunho
              científico);
            </li>
            <li>Estudo Dirigido.</li>
          </ol>
          <Heading type="h4" style={{ margin: '32px 0 16px 0' }} color={ACQUA}>
            Horário do Curso
          </Heading>
          O aluno poderá optar por:
          <br />
          <ol style={{ lineHeight: 1.5 }}>
            <li>
              UMA SEXTA E UM SÁBADO DE CADA MÊS*:
              <br /> Sexta das 19h às 23h, sábado das 8h às 17h (com parada para
              almoço das 12h às 13h) e aula externa no domingo (2 por semestre)
              das 9h às 13h.
            </li>
            <li style={{ marginTop: 32 }}>
              4 AULAS MINISTRADAS POR MÊS ÀS QUARTA-FEIRAS*: <br />
              Quartas-feiras das 19h às 23h e aula externa no domingo (2 por
              semestre) das 9h às 13h
            </li>
          </ol>
          <br />
          <br />
          *Datas determinadas no cronograma de cada semestre.
          <Heading type="h4" style={{ margin: '32px 0 16px 0' }} color={ACQUA}>
            Conteúdo
          </Heading>
          <ul style={{ lineHeight: 2 }}>
            <li>Bases Teóricas da Homeopatia - Lei do Semelhante, medicamento Único, experimentação no indivíduo são, altas diluições;</li>
            <li>Similitude;</li>
            <li>
              Suscetibilidade, Idiossincrasia, Intoxicação, Hipersensibilidade;
            </li>
            <li>Doença Artificial ou Medicamentosa: Conceitos; </li>
            <li>Doses Mínimas;</li>
            <li>Individualização do Doente e do Medicamento;</li>
            <li>Doente e Doença - Doença Aguda e Crônica;</li>
            <li>Cura Conceito e Significado - Níveis de Cura;</li>
            <li>Similar e Simillimum;</li>
            <li>Correntes Terapêuticas;</li>
            <li>Semiologia e Clínica Médica;</li>
            <li>Anamnese Homeopática Veterinária: Peculiaridades;</li>
            <li>Avaliação Clínica;</li>
            <li>Repertórios Homeopáticos;</li>
            <li>Clínica e Terapêutica Homeopática - Diagnósticos;</li>
            <li>Compreensão dos Casos;</li>
            <li>Prescrição Homeopática;</li>
            <li>Potências;</li>
            <li>Avaliação das Leis de Cura - Obstáculos à Cura;</li>
            <li>
              Segunda Prescrição: Conceito - Parâmetros nos Quadros Agudos e
              Crônicos;
            </li>
            <li>Matéria Médica Homeopática;</li>
            <li>Casos Clínicos;</li>
            <li>
              Ambulatório Clínico em Animais Domésticos e Silvestres e Visitas a
              Animais de Produção;
            </li>
            <li>
              Farmácia Homeopática (Veículos Homeopáticos, Formas Farmacêuticas
              e Receituário Homeopático);
            </li>
            <li>
              Metodologia Científica (Observação Clínica, Relato de Caso,
              Casuística Clínica);
            </li>
            <li>
              Estruturação de Modelos Experimentais (Pesquisa Básica, Pesquisa
              Aplicada);
            </li>
            <li>Pesquisas em Homeopatia no Brasil e no Mundo;</li>
            <li>Medicações: <i>Abrotanum, Aconitum Napellus, Aesculus Hippocastanum, Agaricus
Muscrius, Allium Cepa, Alumina, Ammonium Carbonicum, Anacardium Orientale,
Antimonium Tartaricum, Apis Mellifica, Argentum Nitricum, Arnica Montana,
Arsenicum Album, Aurum Metallicum, Baryta Carbonica, Belladonna, Berylium
Metallicum, Blatta Orientalis, Bryonia Alba, Bufo Rana, Calcarea Carbonica
Hahnemanni, Camphora, Carbo Vegetabilis, Carcinosinum Burnett, Caulophyllum
Thalictroides, Causticum Hahnemanni, Chamomilla, Chelidonium Majus, China
Officinalis, Conium Maculatum, Crotalus Horridus, Croton Tiglium, Dioscorea
Vilosa, Drosera Rotundifolia, Dulcamara, Elaps Corallinus, Eupatorium Perfoliatum,
Família Lac, Ferrum Metallicum, Ferrum Phosphoricum, Gelsemium Sempervirens,
Graphites Naturalis, Hepar Sulphuris Calcareum, Hyoscyamus Niger, Ignatia
Amara, Ipecacuanha, Iris Versicolor, Kali Bichromicum, Kali Bromatum, Kali
Carbonicum, Kali Nitricum, Kali Phosphoricum, Kali Sulphuricum, Lachesis
Trigonocephalus, Luesinum, Lycopodium Clavatum, Magnesia Carbonica,
Magnesia Muriatica, Magnesia Phosphorica, Magnesia Sulphurica, Medorrhinum,
Mercurius Solubilis, Naja Tripudians, Natrum Carbonicum, Natrum Muriaticum, Nitri
Acidum, Nux Vomica, Opium, Phosphorus, Platinum Metallicum, Psorinum,
Pulsatilla Pratensis, Pyrogenium, Rheum Officinale, Rhus Toxicodendron, Sanicula
Aqua, Sepia Succus, Silicea Terra, Stannum Metallicum, Staphysagria,
Stramonium, Sulphur, Thuja Occidentalis, Tuberculinum Bovinum Kent, Veratrum
Album, Zincum Metallicum</i>.</li>
          </ul>
        </div>
      </TextContainer>
    </PageContainer>
  </Layout>
)

export default HomeopatiaParaVeterinarios
