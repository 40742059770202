import React from 'react'
import { LIGHT_GREEN, ACQUA, LIGHT_GRAY, BLUE, DARK_GREEN } from '../../constants/Colors';
import styled from 'styled-components'
import { FONT_FAMILY_2 } from '../../constants/FontFamily';
import Link from 'gatsby-link'

const StyledLink = styled(Link)`
    color: ${DARK_GREEN};
    background-color: ${LIGHT_GREEN};
    border: none;
    border-radius: 5px;
    line-height: 36px;
    font-family: ${FONT_FAMILY_2};
    font-size: 16px;
    transition: background-color 250ms ease-out;
    font-weight: 700;
    padding: 10px 20px;
    box-shadow: 0px 2px 5px 0 rgba(54, 45, 43, 0.5);
    cursor: pointer;
    text-decoration: none;

    &:hover{
        background-color: ${ACQUA};
    }

    &:active{
        background-color: ${BLUE};
    }

    &.disabled{
        background-color: ${LIGHT_GRAY};
        cursor: not-allowed;
    }

    @media (max-width: 500px){
        font-size: 12px;
    }

    @media (max-width: 340px){
        font-size: 11px;
    }

`;

const handleClick = (event, disabled) => {

    if(disabled){
        event.preventDefault();
        event.stopPropagation();
    }

}

const LinkButton = ({to, children, style, ...props}) => (
    <StyledLink 
        onClick={e => handleClick(e, props.disabled)} 
        onTouchEnd={e => handleClick(e, props.disabled)}
        {...props} 
        to={to} 
        style={style}
        className={props.disabled ? 'disabled' : ''}
        >
            {children}    
    </StyledLink>
)

export default LinkButton