import React from 'react'
import styled from 'styled-components'

const HeroContainer = styled.div`
    width: 100%;
    height: ${({type}) => type ==='full' ? '511px' : '256px'}; 
    background-image: url('${({image}) => image}');
    background-position: center;

    @media (max-width: 800px) {
        margin-top: 90px;
        background-image: url('${({mobileImage, image}) => mobileImage || image}');
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    @media (max-width: 595px) {
        padding-top: 1px;
        
    }
`


const Hero = ({type, image, mobileImage, children}) => (
    <HeroContainer type={type} image={image} mobileImage={mobileImage}>
        {children}
    </HeroContainer>
)

export default Hero;