import React from 'react'
import Heading from '../../components/typograph/Heading'
import Layout from '../../components/layout/Layout'
import PageContainer from '../../components/layout/PageContainer'
import Hero from '../../components/hero/Hero'
import IlustratedContent from '../../components/ilustratedParagraph/IlustratedContent'
import BoxedContent from '../../components/ilustratedParagraph/BoxedContent'
import styled from 'styled-components'
import { GRAY, BLUE, DARK_GREEN } from '../../constants/Colors'

import coursesImage from '../../images/Banner-Cursos-Desk.jpg'
import coursesImageMobile from '../../images/Banner-Cursos-Mobile.jpg'
import cursoHomeopatiaVetTechDil from '../../images/curso-homeopatia-SCS.png'
import cursoClinicaAvancada from '../../images/curso-clinavancada-SCS.png'
import LinkButton from '../../components/buttons/LinkButton'

const IlustrationsContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1000px;
  padding: 10px;
  margin: 0 auto;
  & div {
    margin-right: 8px;
  }

  @media (max-width: 1130px) {
    flex-wrap: wrap;
  }
`

const MiniCoursesContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1000px;
  padding: 10px;
  margin: 0 auto;

  & div {
    margin: 8px;
  }

  @media (max-width: 985px) {
    flex-wrap: wrap;
  }
`

export const CommonMeta = (page, title) => [
  {
    name: 'keywords',
    content:
      'homeopatia, veterinária, cromoterapia, curso, cursos, mini-curso, mini-cursos',
  },
  {
    property: 'og:url',
    content: 'http://www.hdscience.com.br/' + page,
  },
  { property: 'og:type', content: 'website' },
  { property: 'og:title', content: title },
]

const Courses = () => (
  <Layout
    title="Cursos e Mini-Cursos - HDScience"
    meta={CommonMeta('cursos-e-minicursos', 'Cursos e Minicursos - HDScience')}
  >
    <Hero image={coursesImage} mobileImage={coursesImageMobile}>
      <Heading
        type="h1"
        style={{ color: '#fff', textAlign: 'center', paddingTop: 30 }}
      >
        CURSOS &<br />
        MINI-CURSOS
      </Heading>
    </Hero>
    <PageContainer style={{ marginBottom: 40 }}>
      <Heading
        type="h2"
        style={{ marginBottom: 16, textAlign: 'center' }}
        color={DARK_GREEN}
      >
        Cursos de Longa Duração
      </Heading>
      <IlustrationsContainer>
        <IlustratedContent
          image={cursoHomeopatiaVetTechDil}
          secondary={true}
          style={{
            borderRadius: '20px',
            border: `1px solid ${GRAY}`,
            maxWidth: '240px',
            padding: 20,
            marginTop: 20,
            backgroundColor: BLUE,
            color: DARK_GREEN,
          }}
        >
          <Heading type="h5" style={{ margin: 0 }} color={DARK_GREEN}>
           Homeopatia Veterinária e Tecnologia das Altas Diluições
          </Heading>
          2 anos | Híbrido
          <br />
          <br /> <b>Público: </b> Veterinários.
          <br /> <b>Unidade: </b> São Caetano <br />
          <br />
          <LinkButton to="/cursos-e-minicursos/homeopatia-para-veterinarios">
            SAIBA MAIS AQUI
          </LinkButton>
        </IlustratedContent>
        <IlustratedContent
          image={cursoClinicaAvancada}
          secondary={true}
          style={{
            borderRadius: '20px',
            border: `1px solid ${GRAY}`,
            maxWidth: '240px',
            padding: 20,
            marginTop: 20,
            backgroundColor: BLUE,
          }}
        >
          <Heading type="h5" style={{ margin: 0 }} color={DARK_GREEN}>
            Homeopatia Veterinária: Clínica Avançada
          </Heading>
          12 meses | Online
          <br />
          <br /> <b>Público: </b> Veterinários Homeopatas.
          <br /> <b>Unidade: </b> São Caetano <br />
          <br />
          <LinkButton to="/cursos-e-minicursos/homeopatia-veterinaria-clinica-avancada">
            SAIBA MAIS AQUI
          </LinkButton>
        </IlustratedContent>      
      </IlustrationsContainer>

      <Heading
        type="h2"
        style={{ marginBottom: 16, textAlign: 'center' }}
        color={DARK_GREEN}
      >
        Mini-Cursos (Curta Duração)
      </Heading>

      <MiniCoursesContainer>
        <BoxedContent>
          <Heading type="h5" style={{ margin: 0 }} color={DARK_GREEN}>
            Antroposofia
          </Heading>
          <br />Aguardando interessados para formação de turma e definição de data de início
          <br />
          <br />
          <br /> <b>Unidade: </b> São Caetano <br />
          <br />
          <LinkButton to="https://api.whatsapp.com/send?phone=5511991760079" target="_blank">
            FALE CONOSCO
          </LinkButton>
        </BoxedContent>
        <BoxedContent>
          <Heading type="h5" style={{ margin: 0 }} color={DARK_GREEN}>
            Cromoterapia
          </Heading>
          <br />Aguardando interessados para formação de turma e definição de data de início
          <br />
          <br />
          <br /> <b>Unidade: </b> São Caetano <br />
          <br />
          <LinkButton to="https://api.whatsapp.com/send?phone=5511991760079" target="_blank">
            FALE CONOSCO
          </LinkButton>
        </BoxedContent>
        <BoxedContent>
          <Heading type="h5" style={{ margin: 0 }} color={DARK_GREEN}>
            Estatística
          </Heading>
          <br />Aguardando interessados para formação de turma e definição de data de início
          <br />
          <br />
          <br /> <b>Unidade: </b> São Caetano <br />
          <br />
          <LinkButton to="https://api.whatsapp.com/send?phone=5511991760079" target="_blank">
            FALE CONOSCO
          </LinkButton>
        </BoxedContent>
        <BoxedContent>
          <Heading type="h5" style={{ margin: 0 }} color={DARK_GREEN}>
            Fitoterapia
          </Heading>
          <br />Aguardando interessados para formação de turma e definição de data de início
          <br />
          <br />
          <br /> <b>Unidade: </b> São Caetano <br />
          <br />
          <LinkButton to="https://api.whatsapp.com/send?phone=5511991760079" target="_blank">
            FALE CONOSCO
          </LinkButton>
        </BoxedContent>
      </MiniCoursesContainer>
    </PageContainer>
  </Layout>
)

export default Courses
