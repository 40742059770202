import React from 'react';
import Text from '../typograph/Text';
import styled from 'styled-components';
import { PRIMARY, GRAY } from '../../constants/Colors';

const Container = styled.div`
    text-align: center;
    width:240px;
    background-color: ${PRIMARY};
    border-radius: 20px;
    border: 1px solid ${GRAY};
    padding: 20px;
    margin-top: 20px;
`

const IlustratedContent = ({image, secondary, children, ...props}) => (
    <Container {...props}>
        <Text type='p' secondary>{children}</Text>
    </Container>
)

export default IlustratedContent;