import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FONT_FAMILY_1, FONT_FAMILY_2 } from '../../constants/FontFamily';
import { DARK_GREEN } from '../../constants/Colors';

const H1 = styled.h1`
font-family: ${FONT_FAMILY_1};
font-size: 64px;
font-weight: 900;
line-height: 1.41;
color: ${DARK_GREEN};

@media screen and (max-width: 595px){
    font-size: 40px;
}
`;

const H2 = styled.h2`
font-family: ${FONT_FAMILY_1};
font-size: 40px;
font-weight: 700;
color: ${({color}) => color ? color : DARK_GREEN};

@media screen and (max-width: 500px){
    font-size: 32px;
}

@media screen and (max-width: 340px){
    font-size: 28px;
}
`;

const H3 = styled.h3`
font-family: ${FONT_FAMILY_2};
font-size: 32px;
font-weight: bold;
line-height: 37px;
color: ${DARK_GREEN};

@media screen and (max-width: 500px){
    font-size: 24px;
}
`;

const H4 = styled.h4`
font-family: ${FONT_FAMILY_1};
font-size: 24px;
font-weight: regular;
line-height: 26px;
color: ${({color}) => color ? color : DARK_GREEN};
`;

const H5 = styled.h5`
font-family: ${FONT_FAMILY_2};
font-size: 24px;
font-weight: regular;
line-height: 30px;
color: ${({color}) => color ? color : DARK_GREEN};
`;

const getType = (type, children, props) => {
    const types = {
        h1: <H1 {...props}>{children}</H1>,
        h2: <H2 {...props}>{children}</H2>,
        h3: <H3 {...props}>{children}</H3>,
        h4: <H4 {...props}>{children}</H4>,
        h5: <H5 {...props}>{children}</H5>
    }
    return types[type];

}

const Heading = ({children, type, ...props}) => {

    return getType(type, children, props);
};

Heading.propTypes = {
    type: PropTypes.oneOf(["h1", "h2", "h3", "h4"]).isRequired
}


export default Heading;