import React from 'react';
import Text from '../typograph/Text';
import styled from 'styled-components';

const Container = styled.div`
    text-align: center;
    max-width:285px;
`

const IlustratedContent = ({image, secondary, children, ...props}) => (
    <Container {...props}>
        <img src={image} alt='Representação ilustrativa do curso'/>
        <Text type='p' secondary={secondary}>{children}</Text>
    </Container>
)

export default IlustratedContent;